import 'magnific-popup';

var loaded = false;
document.addEventListener( 'musickitloaded', function () {
	if ( !loaded ) {
		loaded = true;
		let AmkPlaylists = ['pl.b89faa4ce33c4e378e12150ac144fd18'];
		let AmkAlbums = [];

		const sm = new SMEAppleMusic( {
			ae: {
				ae: '7d966967c22fe575eae042270621e3c2f218f9800e9d9a320a8ccb08322ccecd',
				brand_id: '4398348',
				segment_id: '979907',
				activities: '{"actions":{"presave":37790},"mailing_list_optins":{"a0S1p00000USo2BEAT":37791}}'
			},
			am: {
				dev_token: '',
				save_mode: 'library',
				custom_playlist_name: '',
				resources: {
					playlists: AmkPlaylists,
					albums: AmkAlbums
				}
			},
			sf: {
				form: '62637',
				default_mailing_list: 'a0S1p00000USo2BEAT'
			},
			smf: {
				campaign_id: '62637',
				campaign_key: '75c481209492c508fed1d5f9d0368e8e'
			}
		} );
		document.getElementById( 'apple_music_form' ).addEventListener( 'submit', function ( e ) {
			e.preventDefault();

			const email = document.getElementById( 'apple_music_email_address' ).value;

			sm.doActions( email, true ).then( function ( res ) {
				$.magnificPopup.close();
				$.magnificPopup.open( {
					items: {
						src: '#thanks',
						type: 'inline'
					}
				} );
			} ).catch( function ( err ) {
				alert( 'An error has occurred!' );
			} );
		} );
	}
} );

$( document ).ready( function () {
	$( '.btn-apple' ).on( 'click', function ( e ) {
		e.preventDefault();
		$.magnificPopup.open( {
			items: {
				src: '#apple',
				type: 'inline'
			}
		} );
	} );

	$( '.follow form' ).on( 'submit', function ( e ) {
		e.preventDefault();
		const data = $( this ).serialize();
		$.ajax( {
			type: 'POST',
			url: 'https://subs.sonymusicfans.com/submit',
			dataType: 'json',
			data: data,
			xhrFields: {
				withCredentials: false
			},
			success: function ( data ) {
				$( '.follow form' ).html( '<div class="thanks">Thanks for signing up!</div>' );
			},
			error: function ( err ) {
				// Do things when submission has an error.
				alert( 'An error has occurred!' );
			}
		} );
	} );

	let SMEPreSave = {
		state: null,
		spotify_user: null,
		url_vars: [],
		getURLVars: function ( callback, form_id ) {
			let hash;
			let hashes = window.location.href.slice( window.location.href.indexOf( '?' ) + 1 ).split( '&' );
			for ( let i = 0; i < hashes.length; i ++ ) {
				hash = hashes[i].split( '=' );
				this.url_vars.push( hash[0] );
				this.url_vars[hash[0]] = hash[1];
			}
			this.state = 'state' in this.url_vars ? this.url_vars['state'] : null;
			this.ae_member_id = 'ae_member_id' in this.url_vars ? this.url_vars['ae_member_id'] : null;
			this.spotify_user = 'spotify_user' in this.url_vars ? this.url_vars['spotify_user'] : null;
			this.deezer_user = 'deezer_user' in this.url_vars ? this.url_vars['deezer_user'] : null;
			this.message = 'message' in this.url_vars ? this.url_vars['message'] : null;
			if ( typeof callback === 'function' ) {
				callback( this.state, this.ae_member_id, this.spotify_user, this.deezer_user, this.message );
			}
		}
	};

	SMEPreSave.getURLVars( function ( state, ae_member_id, spotify_user, deezer_user, message ) {
		// Check the state of the pre-save. If it was successful, the state will either be success or thank-you.
		if ( state === 'success' || state === 'thank-you' ) {
			$.magnificPopup.open( {
				items: {
					src: '#thanks',
					type: 'inline'
				}
			} );
		}
	} );
} );